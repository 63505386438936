import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`コンプとして、本教材で推奨しているStudio OneのChannel Stripを使う場合は`}</p>
      <p>{`「メーターがないやん、ランプやん！」`}</p>
      <p>{`と思うかもしれませんが、リダクションメーターはStudio Oneのミキサー画面に最初からあります。`}</p>
      <p>{`コンプで圧縮がかかっていると、各トラックのボリュームメーターの右側に、黄色い下向きの細いメーターが動いています。これが、リダクションメーターです（詳しくは、説明書をご覧ください）。`}<br parentName="p"></br>{`
`}{`もし、リダクション量が知りたい場合は、ここを見れば分かります。`}</p>
      <p>{`もう少し言うと、メーターを見るのは「圧縮がかかっているかどうか」を見分けるためなので、見分ける目的なら「点いているかどうか」を見るだけでよいランプの方が`}<strong parentName="p">{`実は簡単`}</strong>{`です。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      